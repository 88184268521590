.cellule {
    width: 100%;
}

#cadreOneCEL {
    background-color: lightgray;

}

#cadreTwoCEL {
    background-color: black;
}

#cadreThreeCEL {
    background-color: rgb(191, 51, 51);
}

#cadreFourCEL {
    background-color: gray;
}

.title {
    font-size: 27px;
    font-weight: bold;
    text-decoration: underline;
}

.subtitle {
    font-size: 20px;
    font-weight: bold;
    padding: 12px 0;
}

ul {
    /*text-align: justify;
    font-size: 19px;*/
    font-weight: normal;
}

@media(max-width: 990px) {
    .cellule {
        margin-bottom: 10px;
    }
}