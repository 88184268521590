.picture {
    float: left;
}

.navLink {
    margin: 0 20px;
}

#topMenu {
    height: 70px;
}

#imgLogo {
    width: 60px;
    height: 60px;
}

.marginImg {
    margin-left: 25px;
}

.imageText {
    float: right;
}

#logoMenu {
    width: fit-content;
    float: left;
}

#menuText {
    float: left;
    margin-left: 15px;
    height: 43px;
    width: 190px;
    margin-top: 0;
}

#imageCoach {
    margin-top: -40px;
}

#textCoachSportif {
    font-size: 15px;
    position: relative;
    top: 25px;
    left: -92px;
}

button {
    background-color: #f8f9fa;
    border: 0;
    color: rgba(0, 0, 0, .55);
    padding: 0 .5rem !important;
}

#panier {
    position: absolute;
    right: 0;
    width: 48px;
}

#buttonPanier {
    margin: 0 !important;
    padding: 0 !important;
}

button:hover {
    color: #495057;
}

#logo {
    font-size: 35px !important;
}

.buttonBasketOff {
    cursor: default !important;
}

.hamburger-react {
    display: block !important;
}

#numberArticleSVG {
    position: relative;
    left: -15px;
    top: -15px;
    z-index: 1;
}

#textNumberArticleSVG {
    font-size: 15px;
}

@media (min-width: 991px) {
    #firstElt {
        margin-left: 150px;
    }

    button {
        padding: 0;
        margin-top: -7px !important;
    }

    .hamburger-react {
        display: none !important;
    }
}

@media(min-width: 991px) and (max-width: 1071px) {
    .topBarText {
        font-size: 14px;
    }

    #menuText {
        width: 140px;
    }

    #firstElt {
        margin-left: 20% !important;
    }
}

/*mobile*/
@media(max-width: 990px) {
    .navLink {
        background-color: white;
        margin: 0;

    }

    .navLinkPadding {
        padding: 8px 20px !important;
    }

    .navLinkDropDownPadding {
        padding: 0 20px !important;
    }

    button.navLink {
        height: 40px !important;
    }

    #topMenu {
        height: 50px;
        padding: 0 !important;
    }

    #imgLogo {
        width: 40px;
        height: 40px;
    }

    button {
        text-align: left;
    }

    #menuText {
        width: 130px !important;
    }

    #panier {
        right: 30px;
    }

    #buttonPanier {
        width: 100%;
        background-color: rgba(0, 0, 0, 0) !important;
    }

    #logo {
        font-size: 27px !important;
    }

    #radio {
        padding: 0 !important;
        width: 30px;
        height: 30px;
        margin-right: 15px;
        box-shadow: none !important;
        outline: none !important;
        border: 0 !important;
    }

    #menuText {
        margin-top: -10px;
    }

    .topBarText {
        font-size: 14px;
    }

    .dropdown-menu {
        -webkit-transition: all .25s;
        -moz-transition: all .25s ease-out;
        -ms-transition: all .25s ease-out;
        -o-transition: all .25s ease-out;
        transition: all .25s ease-out;

        max-height: 0;
        display: block;
        overflow: hidden;
        opacity: 0;
        padding: 0 20px !important;
        margin: 0 !important;
    }

    .dropdown-menu.show {
        max-height: 200px;
        opacity: 1;
    }
}

@media(max-width: 378px) {
    /*#imageZone {
        width: 70%;
    }*/

    #textNameCoachSportif {
        font-size: small;
    }

    #textCoachSportif {
        font-size: small;
    }
}

@media(max-width: 312px) {
    #textNameCoachSportif {
        font-size: xx-small;
    }

    #textCoachSportif {
        font-size: xx-small;
    }

    #imageZone {
        width: 50%;
    }

    .logo {
        width: 20%;
    }

    #radio {
        width: 10%;
        height: 10%;
        padding: 0 !important;
    }

    .navbar-toggler-icon {
        width: 85% !important;
    }

}

@media(max-width: 276px) {
    #textNameCoachSportif {
        position: relative;
        top: -50px;
        left: 30px;
    }
}