.sepa {
    height: 2px;
    background-color: #9b9b9b;
}

#offsetMenu {
    padding-top: 100px;
}

#votreCoach {
    margin-top: -80px;
}