body {
    margin: 0;
    padding: 0;
    /*display: grid;
    min-height: 100vh;*/
}

html {
    scroll-behavior: auto !important;
}

.logo {
    border-radius: 15px;
}

.rowEmptyMargin {
    margin: 0 !important;
}

@media(min-width: 991px) {
    h1 {
        font-size: 30px !important;
    }

    h2 {
        font-size: 27px !important;
    }

    h3 {
        font-size: 24px !important;
    }

    h4 {
        font-size: 21px !important;
    }

    h5 {
        font-size: 17px !important;
    }

    p {
        font-size: 15px !important;
    }
}

@media(max-width: 990px) {
    h1 {
        font-size: 22px !important;
    }

    h2 {
        font-size: 16px !important;
    }

    h3 {
        font-size: 15px !important;
    }

    h4 {
        font-size: 14px !important;
    }

    h5 {
        font-size: 13px !important;
    }

    p {
        font-size: 12px !important;
    }
}