#imgHome {
    width: 100%;
    height: 600px;
}
#texteOverBg {
    position: relative;
    top: -319.2px;
    color: white;
    width: fit-content !important;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, .75);
    padding: 10px;
    border-radius: 30px;
    border: solid white 1.5px;
    text-align: center;
}