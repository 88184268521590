#empty {
    margin: 60px 0;
}

.liStyle {
    list-style: none;
}

.textPanierVide {
    text-align: center;
}

.basketButton {
    width: 100%;
    margin-top: 25px !important;
}

#emptyBsket {
    margin: 10px 0;
}

#paidButon {
    margin: 15px 0 25px 0 !important;
}

.buttonsBasket {
    width: 100%;
    height: 40px;
}

#creditCardInfo {
    margin-top: 2px;
}

.tarifBasket {
    margin-top: 11.25px !important;
}

#recapBasket {
    padding: 10px;
}

#cadreBasket {
    width: 80%;
}

#formPaid {
    /*max-width: 800px;
    margin-left: auto !important;
    margin-right: auto !important;
    margin: 25px;*/
    padding: 25px;
    border: solid black 1px;
    border-radius: 12px;
}

#titleCB {
    margin: 0 0 5px 5px;
}

.cadreBasket {
    border: solid 1px grey;
    border-radius: 6px;
    box-shadow: 2px 2px 5px gray;
}

.cardForm {
    padding: 8px;
}

#cardNumberElet {
    border-bottom: solid 1px grey;
}

#sepaPaidForm {
    border-right: solid 1px grey;
}

#sepaBasket {
    display: none;
}

#buttonPaySection {
    margin: 0 0 32px 0;
}

.pricePromoBasket {
    color: green;
}

.oldPriceBasket {

    position: relative;
}


span.oldPriceBasket::before {
    content: '';
    width: 100%;
    position: absolute;
    right: 0;
    top: calc(50% - 1.5px);
    border-bottom: 2px solid rgba(255, 0, 0, 0.8);
}

.pricePromoBasketRecap {
    font-size: 20px;
}

.oldPriceBasketRecap {
    font-size: 15px !important;
}

.pricePromoBasketTotal {
    font-size: 35px;
}

.oldPriceBasketTotal {
    font-size: 30px !important;
}

/* computer */
@media(max-width: 990px) {
    #buttonPaySection {
        margin: 10px 0 0px 0 !important;
    }

    #formPaid {
        border: none !important;
    }

    #cadreBasket {
        width: 100% !important;
    }

    #sepaBasket {
        display: block;
        height: 1px;
        background-color: gray;
        margin-bottom: 30px;
    }

    #paidButon {
        margin: 40px 0 0px 0 !important;
    }

    #rowRecap {
        margin: 3px;
    }
}