#pagination {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.pagination .active a {
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
    color: white !important;
}

.pagination a {
    color: black !important;
}