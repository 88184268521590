.startStop {
    width: 16.66% !important;
}

#delimiter {
    margin-top: 20px;
}

#cadreOneCES {
    background-color: lightgray;

}

#cadreTwoCES {
    background-color: black;
}

#cadreThreeCES {
    background-color: darkblue;
}

#cadreFourCES {
    background-color: rgb(191, 51, 51);
}

#cadreFiveCES {
    background-color: gray;
}

@media(max-width: 990px) {
    #delimiter {
        margin-top: 0 !important;
    }
}