#container {
    background-color: black;
}

.paddingText {
    padding: 12px 0;
}

#sepaRed {
    width: 50%;
    height: 4px;
    background-color: red;
    margin-left: auto;
    margin-right: auto;
}

@media(max-width: 990px) {
    #sepaRed {
        width: 100%;
    }
}