.sepaTop {
    padding: 25px 0;
}

#text {
    text-align: center;
}

#bio {
    padding: 0 18px;
}

#cadre {
    padding: 0 75px;
    max-width: 100%;

    margin-left: auto;
    margin-right: auto;
}

#picVal {
    width: 100%;
    max-height: 600px;
    border: solid 5px black;
    border-radius: 35px;
}

@media (min-width: 991px) {
    #bio {
        padding: 0 100px;
    }

    #cadre {
        height: 600px;
    }

    #textBio {
        font-size: 18px;
    }

    #sectionCenterText {
        transform: translateY(50%);
    }




}

@media(max-width: 990px) {
    .homeRow {
        --bs-gutter-x: 0 !important;
    }
}

/*
.row {
    --bs-gutter-x: 0 !important;
}
*/