#cadreOne {
    background-color: rgb(0, 0, 0);
}

#cadreTwo {
    background-color: grey;
}

#cadreThree {
    background-color: rgb(173, 16, 16);
}

.titleDetails {
    margin-bottom: 25px;
}

.contentDetails {
    width: fit-content !important;
    margin-left: auto;
    margin-right: auto;
}

.descriptionDetails {
    text-align: justify;
}