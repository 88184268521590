select,
button {
    height: 55px;
}

.justifyText {
    text-align: justify;
}

.priceText {
    font-size: 55px !important;
}

#pricePromo {
    color: green;
}

#oldPrice {
    font-size: 35px !important;
    position: relative;
}

span#oldPrice::before {
    content: '';
    width: 100%;
    position: absolute;
    right: 0;
    top: calc(50% - 1.5px);
    border-bottom: 6px solid rgba(255, 0, 0, 0.8);
}

@media(max-width: 990px) {
    .priceText {
        font-size: 55px !important;
    }

    #title {
        font-size: 4vh !important;
    }
}