/*#footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
*/
#urlNameWebSite {
    text-decoration: none;
}

#marginBetweenNameWebSiteAndLogo {
    margin-left: .6rem;
}

#instaBTN {
    width: 40px;
    height: 40px;
    padding: 0 !important;
}

#logoBarFooter {
    margin-top: 25px;
}

.logoCenterFooter {
    margin-left: auto;
    margin-right: auto;
}

#mentionsLegales {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
}

.textFooter {
    text-align: center;
}

#rightFooterInfos {
    margin-top: 71px !important;
}

@media(max-width: 990px) {
    .textFooter {
        text-align: left;
    }

    #rightFooterInfos {
        margin-top: 0px !important;
    }
}