#contactMeTitle {
    text-align: center;
    margin: 25px 0;
    font-weight: bold;
    font-size: 50px;
}

#formContact {
    padding-bottom: 100px;
}

#sendFormContact {
    width: 100%;
}