#sendFormLeaveAReview {
    width: 100%;
}

#spinnerReviewContainer {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 0;
}

#spinnerReview {
    margin-left: 35%;
}

@media(max-width: 990px) {
    #sendFormLeaveAReview {
        width: 100%;
    }
}