#title {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    font-size: 55px;
}

#tarifTitle {
    padding: 80px 0;
}

#tarifLegend {
    margin-top: 33.5px;
}

.formMiddle {
    margin: 15px 0;
}

#sub {
    width: 100%;
}

.cadre {
    padding: 20px;
    border-radius: 15px;
}

.textDetails {
    font-size: 20px;
    font-weight: bold;
}

#cadreOne {
    background-color: black;
}

#cadreTwo {
    background-color: grey;
}

#cadreThree {
    background-color: rgb(173, 16, 16);
}

.titleDetails {
    margin-bottom: 25px;
}

.contentDetails {
    width: fit-content !important;
    margin-left: auto;
    margin-right: auto;
}

.descriptionDetails {
    text-align: justify;
}

select,
button {
    height: 55px;
}

@media(max-width: 990px) {
    #tarifTitle {
        padding: 30px 0;
    }

    .cellule {
        margin-bottom: 10px;
    }
}